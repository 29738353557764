<template>
    <div class="setting">
        <van-nav-bar
            title="设置"
            fixed
            left-arrow
            @click-left="$router.go(-1)"
        />
        <div class="cells">
            <van-cell-group title="地址">
                <van-cell icon="location-o" title="地址设置" is-link :to="{name:'address'}" />
            </van-cell-group>
            <van-cell-group  title="视频号">
                <van-cell icon="video-o" title="视频号" is-link :to="{name:'sph'}" />
            </van-cell-group>
            <van-cell-group  title="实名认证">
                <van-cell icon="idcard" title="实名认证" is-link :to="{name:'kyc'}" />
            </van-cell-group>
            <van-cell-group title="协议">
                <van-cell icon="records-o" title="服务协议" is-link :to="{name:'agree',params:{t:'user'}}" />
                <van-cell icon="records-o" title="隐私条款" is-link :to="{name:'agree',params:{t:'private'}}" />
                <van-cell icon="records-o" title="个人信息保护协议" is-link :to="{name:'agree',params:{t:'protect'}}" />
            </van-cell-group>
            
            <div class="logout-btn">
                <van-button plain hairline block type="danger" size="normal" @click="logout">退出登录</van-button>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'Setting',
    data(){
        return {
            
        }
    },
    components:{
    },
    mounted(){

    },
    methods:{
        logout(){
            this.$store.commit('setUserinfo', {});
            this.$store.commit('setToken', {});
            this.$router.push({name: 'home'});
        }
    }
}
</script>
<style scoped lang="less">
.setting{
    min-height: 100vh;
    .cells {
        margin-top: 50px;
        .logout-btn {
            margin: 15px;
        }
    }
    
}


</style>
